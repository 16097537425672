<template>
  <!-- 实验报告 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验报告</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="maintext">关键字:</div>
          <el-input
            class="elinput2"
            placeholder="请输入实验课程/实验项目/报告名称"
            v-model="form.keyword"
            clearable
          ></el-input>

          <div style="margin-left: 60px" class="maintext">提交状态:</div>
          <el-select class="elinput" v-model="form.status" placeholder="请选择">
            <el-option
              v-for="item in substatusoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <div style="margin-left: 60px" class="maintext">批阅状态:</div>
          <el-select class="elinput" v-model="form.check_status" placeholder="请选择">
            <el-option
              v-for="item in reviewstatusoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div style="margin-left: 60px" class="maintext">提交时间:</div>
          <el-date-picker
            class="elinput2"
            v-model="timevalue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div class="right">
          <div class="bt restbt" @click="searchbt">查询</div>
          <div class="bt searchbt" @click="resetbt">重置</div>

          <div class="bt restbt" @click="newadd">新增</div>
        </div>
      </div>

      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        class="Table"
        stripe
        :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#FFFFFF', }"
        :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }"
      >
        <el-table-column prop="title" label="报告名称"></el-table-column>
        <el-table-column prop="module_name" label="模块名称"></el-table-column>

        <el-table-column prop="created_at" label="提交时间">
          <template slot-scope="scope">{{ scope.row.created_at | dataformat }}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">{{ scope.row.status === 0 ? "未提交" : "已提交" }}</template>
        </el-table-column>
        <el-table-column prop="score" label="得分"></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="operatcontain">
              <el-button class="opreatbt" @click="viewreport(scope.row)">查看</el-button>
              <el-button
                v-if="scope.row.status===0"
                class="opreatbt"
                @click="toeditor(scope.row.id)"
              >编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="form.page"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
    <Customdialog
      ref="customdialog"
      class="customdialog"
      width="700px"
      type="max"
      title="实验报告"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <div class="dialogcontain" v-if="itemdetailShow">
          <div class="sectioncontain">
            <div class="title">实验标题:</div>
            <div class="content">{{itemdetail.title}}</div>
          </div>
          <div class="sectioncontain dialogmain">
            <div class="title">实验报告:</div>
            <div class="content">
              <div v-html="itemdetail.content"></div>
              <div class="annex">
                附件：
                <el-button
                  type="text"
                  v-if="itemdetail.file.length > 0"
                  @click="seeFiles(itemdetail.file[0].url)"
                >查看</el-button>
                <!-- <el-button type="text" v-if="itemdetail.file.length > 0" @click="downFiles(itemdetail.file[0].url)">
                  下载
                </el-button>-->
              </div>
            </div>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评语:</div>
            <div class="content">{{itemdetail.reply}}</div>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评分:</div>
            <div class="content">{{itemdetail.score}}</div>
          </div>
        </div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import dayjs from "dayjs"
import {
  getvrstudyreportlist,
  getvrstudyreportinfo,
} from "@/api/vrstudyreport"
import Customdialog from "@/components/customdialog.vue"
export default {
  name: "mainpage",
  components: {
    Customdialog,
  },
  data () {
    return {
      timevalue: "",
      total: 0,
      itemdetail: "",
      itemdetailShow: false,
      form: {
        vr_id: "",
        keyword: "",
        status: "",
        check_status: "",
        start_time: "",
        end_time: "",
        page: 1,
        per_page: 10
      },
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      substatusoptions: [
        {
          value: 0,
          label: "未提交",
        },
        {
          value: 1,
          label: "已提交",
        },
      ],

      reviewstatusoptions: [
        {
          value: 0,
          label: "未批阅",
        },
        {
          value: 1,
          label: "已驳回",
        },
        {
          value: 2,
          label: "已批阅",
        },
      ],
      vr_id: "",
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  computed: {},
  created () {
    this.form.vr_id = this.$route.query.vr_id
    this.vr_id = this.$route.query.vr_id
    this.getvrstudyreportlist()
  },
  methods: {
    searchbt () {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.form.start_time = dayjs(this.timevalue[0]).unix()
        }
        if (this.timevalue[1]) {
          this.form.end_time = dayjs(this.timevalue[1]).unix()
        }
      } else {
        this.form.start_time = ""
        this.form.end_time = ""
      }
      this.getvrstudyreportlist()
    },
    resetbt () {
      this.form.keyword = ""
      this.form.status = ""
      this.form.check_status = ""
      this.timevalue = ""
      this.form.start_time = ""
      this.form.end_time = ""
      this.form.page = 1
      this.form.per_page = 10
      this.getvrstudyreportlist()
    },
    pageChange (val) {
      this.form.page = val
      this.getvrstudyreportlist()
    },
    viewreport (item) {
      this.$refs.customdialog.dialogopenbt()
      this.getvrstudyreportinfo({
        id: item.id,
      })
    },
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 0,
          componentId: "Virtualsimulationexperiment",
        },
      })
    },
    toeditor (itemid) {
      this.$router.push({
        path: "/home/personalcenter/veditorreport",
        query: {
          vr_id: this.vr_id,
          itemid: itemid,
          type: '编辑'
        },
      })
    },
    newadd () {
      this.$router.push({
        path: "/home/personalcenter/veditorreport",
        query: {
          vr_id: this.vr_id,
        },
      })
    },
    getvrstudyreportlist () {
      getvrstudyreportlist(this.form)
        .then((response) => {
          this.total = response.data.total
          this.tableData = response.data.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },

    getvrstudyreportinfo (params) {
      getvrstudyreportinfo(params)
        .then((response) => {
          if (response.data) {
            this.itemdetail = response.data
            this.itemdetailShow = true
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 查看下载
    seeFiles (fileUrl) {
      // let newUrl = this.$api.baseUrl + fileUrl
      let doc_ext = fileUrl.substring(fileUrl.lastIndexOf(".") + 1)

      const typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
      let url = '' // 接收文件链接 
      if (typeArr.indexOf(doc_ext) !== -1) {
        // 使用微软的office online
        // url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileUrl)
        url = "https://view.xdocin.com/view?src=" + encodeURIComponent(fileUrl)
      } else {
        url = fileUrl
      }
      // window.open()打开
      window.open(url, "_blank")
    },
    // 下载模板
    downFiles (fileUrl) {
      let suffix = fileUrl.substring(fileUrl.lastIndexOf(".") + 1)
      let newName = "实习报告." + suffix

      var aLink = document.createElement("a")
      aLink.style.display = "none"
      aLink.href = fileUrl
      aLink.target = "_blank"
      aLink.setAttribute("download", newName)
      document.body.appendChild(aLink)
      aLink.click()
      document.body.removeChild(aLink) //下载完成移除元素
    },

  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background: #ffffff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .searchcontain {
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .maintext {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .elinput2 {
          width: 250px;
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }
        .restbt {
          margin-right: 10px;
          background: #3d84ff;
          color: white;
        }
        .searchbt {
          margin-right: 10px;
          border: 1px solid #3d84ff;
          color: #3d84ff;
        }
      }
    }
    .newadd {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
      }
      .restbt {
        margin-right: 20px;
        background: #3d84ff;
        color: white;
      }
    }
    .tipcontain {
      margin-top: 10px;
      height: 40px;
      background-color: rgb(230, 247, 255);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 40px;
      .text {
        font-size: 18px;
        color: #3d84ff;
        padding-left: 5px;
        padding-right: 5px;
      }
      .bt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
    }

    .Table {
      width: 100%;
      // border: 1px solid red;
      .operatcontain {
        display: flex;
        justify-content: center;
        align-items: center;
        .opreatbt {
          border: none;
          background: none;
          margin: 10px;
          padding: 0px;

          color: #3d84ff;
        }
      }
    }

    .elpagination {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
  .customdialog {
    // border: 1px solid red;
    .dialogbody {
      .dialogcontain {
        padding: 10px 20px;
        .sectioncontain {
          width: 100%;
          .title {
            color: #222222;
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            line-height: 2;
          }
          .content {
            color: #222222;
            font-size: 14px;
            line-height: 2;
          }
          .annex {
            margin: 10px 0;
          }
        }
        .dialogmain {
          .content {
            text-indent: 2em;
          }
          .annex {
            text-indent: 0;
          }
        }
      }
    }
    .dialogfooter {
      // border: 1px solid red;
      border-top: 1px solid #d6d6d6;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .opreatbt {
        margin-right: 60px;
        width: 140px;
        height: 42px;
        background: #3d84ff;
        border-radius: 4px;
        font-size: 16px;

        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.btgroup {
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #3d84ff;
  height: 60px;
  width: 100%;
  .bt {
    margin-top: 10px !important;
    width: 100%;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}
</style>
